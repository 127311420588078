// Flowtype

require("./src/script/flowtype");

window.flowtype(document.body, {
  minimum: 500,
  maximum: 1200,
  minFont: 14,
  maxFont: 40,
  fontRatio: 72
});

// End of Flowtype

// Contact Form

(() => {
  const contactForm = document.getElementById("contact-form");
  const mailAlias = "hi";
  const mailProvider = "cenksoykan.com";
  if (contactForm) {
    contactForm.setAttribute(
      "action",
      "//formspree.io/" + mailAlias + "@" + mailProvider
    );

    contactForm.addEventListener("submit", (e) => {
      localStorage.clear();
    });
  }

  const message = document.getElementById("message");
  if (message) {
    message.value = localStorage.getItem('message');
    message.oninput = () => {
      localStorage.setItem('message', message.value)
    };
  }
})();

// End of Contact Form

// Canvas Confetti

import confetti from "canvas-confetti";

const count = 200;
const defaults = {
  origin: { y: 0.7 },
};

function fire(particleRatio, opts) {
  confetti(
    Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
    }),
  );
}

function confettiRealistic() {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

const buttons = document.querySelectorAll("[data-confetti-button]");

buttons.forEach((button) => {
  button.addEventListener("click", () => confettiRealistic());
});

// End of Canvas Confetti

//////////////////////////////////////////////////

(() => {
  let buttons = [
    ...document.getElementsByTagName("button"),
    ...document.getElementsByClassName("button")
  ];

  if (buttons) {
    for (var i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener("mousedown", (e) => e.preventDefault());
      buttons[i].addEventListener("touchstart", (e) => { }, { passive: true });
    }
  }

  const avatar = document.getElementsByClassName("avatar");

  if (avatar) {
    const clone = avatar[0].cloneNode(true);

    document.getElementById("header-background").appendChild(clone);
  }

  window.onload = (e) => {
    document.body.classList.remove("preload");
  };

  console.info(
    `\nMade with %c♥%c in Türkiye\nCopyright © ${new Date().getFullYear()} Cenk SOYKAN.\n\n`,
    'color: #e11d48', 'color: unset'
  );
})();
